<template>  
  <span class="wm-100">

    <b-form-input :state="validated" v-model="input_validated" @focus="changePnr" v-if="validated"></b-form-input>

    <IMaskComponent :key="edited" :class="getClass" autocomplete="off" v-if="!validated"
      v-model="input_value"
      :mask="mask"
      radix="."
      :autofix="autofix"
      ref="pnr"
      :overwrite="overwrite"
      @blur="hidePnr"
      @accept="acceptData"
      @complete="completeData"
      :placeholder="$t('SPECIFY.PNR')"
    ></IMaskComponent>

  </span>
</template>
<script>
import {IMaskComponent} from 'vue-imask';
export default {
  components: { IMaskComponent },
  props: ["value"],
  data() {
    return {
      edited: 0,
      focus: false,
      input_validated: '',
      validated: false,
      input_value: '',
      overwrite: true,
      autofix: true,
      mask: '00000000-0000'
    };
  },
  methods: {
    hidePnr()
    {
      if(this.input_value != "")
      {
        if (this.validatePNum(this.input_value)) {
          this.validated = true;
          this.input_validated = this.input_value.split('-')[0] + "-XXXX";
          this.$emit("valid",true);
        } else {
          this.$emit("valid",false);
        }
      }else{
        this.$emit("valid",false);
      }
      this.$emit("input", this.input_value);
    },
    changePnr()
    {
      let self = this;
      this.validated = false;
      setTimeout(function(){
        self.$refs['pnr'].$el.focus();
      }, 70);
     },
    acceptData: function()
    { 
      this.$emit("valid",false);       
      this.$emit('input','');
    },
    completeData: function(value)
    {
      if(this.validatePNum(value))
      {
        this.input_validated = value.split('-')[0] + "-XXXX";
        this.validated = true;
        this.$emit("valid",true);
      }else{
        this.input_validated = "";
        this.validated = false;
        this.$noty.error(this.$t("ERROR.NOT_VALID"));        
        this.$emit("valid",false);
      }
      this.$emit("input",value);
    },
    formatter(value) {
      return value.toLowerCase()
    }
  },
  computed: {
    getClass(){
      if(this.validated)
      {
        return ["form-control"];
      }else{
        return ["is-invalid", "form-control"];
      }
      
    }
  },
  watch: { 
    input_value: function(newVal) {
      let self = this;
      if( (newVal.includes("-") && newVal.length == 11) )
      {          
        if(!newVal.startsWith("1") && !newVal.startsWith("2") )
        {
          this.input_value = "19" + newVal;
          this.edited = this.edited + 1;
          if(!this.validated)
          {
            if(self.$refs['pnr'].$el != undefined)
            {
              setTimeout(function(){ self.$refs['pnr'].$el.focus(); }, 100);
            }
          }
        }
      }      
    }
  },
  mounted() {
    if(this.value != undefined)
    {
      this.input_value = this.value;
    }
    this.$refs['pnr'].$el.focus();
    this.$emit("valid",false);    
  }
};
</script>
<style></style>
