<template>
  <section>
    <b-form-input
      type="text"
      @input="checkValid"
      autocomplete="off"
      ref="phone"
      :state="valid"
      v-model="input_value"
    ></b-form-input>
  </section>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      input_value: this.value,
      valid: false
    };
  },
  methods: {
    checkValid: function () {
      this.valid = this.validateEmail(this.input_value);
      this.$emit("valid", this.valid);
      this.$emit("input", this.input_value);
    },
  }
};
</script>
<style></style>